import languageTypes from "./language.types";

const INITIAL_STATE = {
  currentLanguage: "",
  sightsModal: true,
  popup: true,
};

const languageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case languageTypes.LANGUAGE_CHANGE_SUCCESS:
      return {
        ...state,
        currentLanguage: action.payload,
      };
    case languageTypes.CLOSE_MODAL:
      return {
        ...state,
        sightsModal: false,
      };
    case "CLOSE_POPUP":
      return {
        ...state,
        popup: false,
      };
    default:
      return state;
  }
};

export default languageReducer;
