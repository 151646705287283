const INITIAL_STATE = {
  isVisible: false,
  sectionClicked: null,
  currency: "EUR",
};

const timetableReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "TOGGLE_VISIBLE":
      return {
        ...state,
        isVisible: action.payload,
      };
    case "SECTION_CLICKED":
      return {
        ...state,
        sectionClicked: action.payload,
      };
    case "SET_CURRENCY":
      return {
        ...state,
        currency: action.payload,
      };
    default:
      return state;
  }
};

export default timetableReducer;
