const INITIAL_STATE = {
  affiliate: ''
};

const affReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_AFF':
      return {
        ...state,
        affiliate : action.payload
      };
    
    default:
      return state;
  }
};

export default affReducer;