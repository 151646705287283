const INITIAL_STATE = {
  ticket: "",
  time: "",
};

const ticketReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ADD_TICKET":
      return {
        ...state,
        ticket: action.payload,
        time: new Date(),
      };
    case "CLEAR_TICKET":
      return {
        ...state,
        ticket: "",
        time: "",
      };

    default:
      return state;
  }
};

export default ticketReducer;
