import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import createSagaMiddle from "redux-saga";
import { persistStore } from "redux-persist";
// import logger from 'redux-logger';
import rootReducer from "./rootReducer";

const sagaMiddleware = createSagaMiddle();
export const middlewares = [thunk, sagaMiddleware];

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

export const persistor = persistStore(store);

const exportedObject = {
  store,
  persistor,
};

export default exportedObject;
