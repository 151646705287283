const INITIAL_STATE = {
  date: Date.parse(Date("2021/01/01")),
  passangers: 90000,
};
const passangerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CALCULATE_PASSANGERS":
      return {
        ...state,
        passangers: INITIAL_STATE.passangers + action.payload,
      };
    case "UPDATE_DATE":
      return {
        ...state,
        date: Date.now(),
      };
    default:
      return state;
  }
};

export default passangerReducer;
