import modalTypes from "./modal.types";

const INITIAL_STATE = {
  isModalW: true,
  is2024modal: true,
};

const modalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case modalTypes.ADD_MODAL:
      return {
        ...state,
        isModalW: action.payload,
      };
    case modalTypes.TOGGLE_2024:
      return {
        ...state,
        is2024modal: action.payload,
      };
    default:
      return state;
  }
};

export default modalReducer;
