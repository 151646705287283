import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
// import './index.css';

import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/createStore";
import { PersistGate } from "redux-persist/integration/react";
import "./i18n";
import ClipLoader from "react-spinners/ClipLoader";

import { BrowserRouter } from "react-router-dom";

// import '../src/assets/css/flickity.css';
const App = lazy(() => import("./App"));
ReactDOM.render(
  <PersistGate persistor={persistor}>
    <Provider store={store}>
      <BrowserRouter>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                backgroundColor: "#252a30",
                minWidth: "100%",
                minHeight: "100dvh",
                overflow: "hidden",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <ClipLoader size={200} color="#D9AE63" />
            </div>
          }>
          <App />
        </Suspense>
      </BrowserRouter>
    </Provider>
  </PersistGate>,
  document.getElementById("root")
);
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
